#pg_title {
    text-align: center;
  }
  
  #pg_title_tag {
    text-align: center;
    line-height: 2%;
  }
  
  .ps_cards {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 5%;
  }
  h2{
      margin-left: 4%;
  }
  
  .s_card {
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 1%;
    border-radius: 12px;
    text-align: center;
    background: #ffffff;
    height: 46rem;
    width: 25rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .s_card h3{
      margin-top: 0%;
      margin-bottom: 0%;
  }
  .s_card p{
      font-size: medium;
      margin-top: 1.5%;
      line-height: 1.5rem;
      font-weight: 600;
  }
  
  #last{
      height: 35rem;
  }
  .s_card img{
      height: 60%;
  }
  
  #pneumatic_imgs{
      width: 100%;
      height: auto;
  }
  
  #shot, #x630{
      width: 100%;
  }
  
  .ps_card {
      padding-left: 1%;
      padding-right: 1%;
      padding-top: 1%;
      border-radius: 12px;
      width: 20%;
      text-align: center;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  
  #hoses_p{
      text-align: center;
      font-size: 1em;
      line-height: 2em;
      padding-left: 4%;
      padding-right: 4%;
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  @media only screen and (max-device-width:480px) {
      .ps_cards{
          display: flex;
          flex-direction: column;
      }
  
      .s_card{
          width: 90%;
          margin: 3%;
          height: auto;
          padding-bottom: 1rem;
      }
      #last{
          height: auto;
          padding-bottom: 1rem;
      }
      .s_card h3{
          padding-bottom: 4%;
      }
      .s_card img{
          width: 90%;
      }
      #hoses_p{
          line-height: 1.5em;
      }
  
      .ps_cards{
          display: flex;
          flex-direction: column;
          margin-top: 0em;
      }
  
      .ps_card{
          width: 90%;
          margin: 3%;
      }
  
      .ps_card h3{
          font-size: medium;
          padding-bottom: 4%;
      }
  
      #pg_title{
          font-size: larger;
      }
  }
  
  
  @media (min-device-width:768px)and (max-device-width:1024px){
  #pg_title{
      font-size: 1.5em;
      margin-top: 2%;
  }
  #hoses_p{
      font-size: .9em;
      line-height: 1.4em;
  }
  .s_card{
      width: 30%;
  }
  .s_card img{
      width: 100%;
  }
  .s_card h3{
      font-size: 1em;
      margin-bottom: 2%;
      font-weight: 600;
  }
  
  #pg_title{
      font-size: 1.4em;
      margin-top: 5%;
  }
  #hoses_p{
      margin: 2%;
  }
  .ps_card{
      width: 22%;
  }
  .ps_card img{
      width: 100%;
  }
  .ps_card h3{
      font-size: .9em;
      font-weight: 600;
      margin-top: 2%;
      margin-bottom: 3%;
  }
  }